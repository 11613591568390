/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    var $headerMain = $('header.banner'),
        $headerOffset = $('.header-offset'),
        $navbarPrimary = $('#navbarPrimary'),
        $megaMenuDropdown = $('.mega-menu .level-0.dropdown'),
        $megaMenu = $('.mega-menu'),
        $dropdownToggle = $('.mega-menu .toggle-submenu'),
        $sliderLogo = $('.slider-logo'),
        $partnerLogos = $('.partner-logos .logos'),
        navBreakpoint = 1366,
        xlBreakpoint = 1170,
        smBreakpoint = 992,
        xsBreakpoint = 576;


    // return true, if mobile device
    function mobileHelper() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || $(window).width() <= navBreakpoint) {
            return true;
        }
        return false;
    }

    /**
     * return true, if screen is smaller than x
     * @param brekpoint
     * @returns {boolean}
     */
    function smallScreenHelper(brekpoint) {
        if ($(window).width() <= brekpoint) {
            return true;
        }
        return false;
    }

    function slideDropdownMenu($dropdown) {
        $dropdown.mouseenter(function (e) {
            e.stopPropagation();
            $(this).parent('.navbar-nav').addClass('open');
            $(this).addClass('is-open');
            $(this).find('.dropdown-menu').first().stop(true, true).slideDown(200);
        });
        $dropdown.mouseleave(function () {
            $(this).parent('.navbar-nav').removeClass('open');
            $(this).removeClass('is-open');
            $(this).find('.dropdown-menu').first().stop(true, true).slideUp(200);
        });
    }

    function scrollWindow(scrollClass, scrollElement, elementOffset) {
        var scrollPosition = $(window).scrollTop(), elementOffsetTop = elementOffset.offset().top;

        // store threshold for later use
        if (typeof window.stickyThreshold === 'undefined') {
            window.stickyThreshold = elementOffsetTop;
        }

        if (typeof window.elementOffsetTop === 'undefined') {
            window.elementOffsetTop = elementOffsetTop;
        }

        if (scrollPosition > window.stickyThreshold) {
            scrollElement.addClass(scrollClass);
            return;
        }

        // revert changes when scrolling back to top
        if (scrollPosition < window.stickyThreshold) {
            scrollElement.removeClass(scrollClass);
            return;
        }
    }

    /**
     * Show/hide Team Profiles
     *
     * @param $list
     */
    function loadTeamProfiles($list) {

        var sizeLi = $list.find('li.profile').size(),
            shownElements = $list.find('li.shown').size(),
            $btnMore = $list.find('.load-more'),
            $btnLess = $list.find('.show-less'),
            load = 3;

        $list.find('li.profile:lt(' + load + ')').removeClass('d-none').addClass('shown');

        $btnMore.click(function () {
            load = (load + 2 <= sizeLi) ? load + 2 : sizeLi;
            $list.find('li.profile:lt(' + load + ')').removeClass('d-none').addClass('shown');
            shownElements = $list.find('li.shown').size();
            if (shownElements >= sizeLi) {
                $btnMore.addClass('d-none');
                //  $btnLess.removeClass('d-none');
            }
        });

        $btnLess.click(function () {
            load = (load - 2 < 0) ? load : load - 2;
            $list.find('li.profile').not(':lt(' + load + ')').removeClass('shown').addClass('d-none');
            shownElements = $list.find('li.shown').size();
            $btnMore.removeClass('d-none');
            $btnLess.addClass('d-none');
        });
    }

    function movexsElements() {
        if (smallScreenHelper(xsBreakpoint)) {
            if ($partnerLogos.length > 0) {
                var content = $partnerLogos.contents();
                $('.partner-logos-footer').html(content);
            }
        }
    }

    function onScrollMobile() {
        if (smallScreenHelper(smBreakpoint)) {

        }
    }

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
                if (!mobileHelper() && !smallScreenHelper(navBreakpoint)) {
                    $(document).ready(function () {
                        slideDropdownMenu($megaMenuDropdown);
                    });

                }

                $megaMenuDropdown.find('a').click(function (e) {
                    if ($(this).attr('href') === '#') {
                        e.preventDefault();
                        return false;
                    }
                });

                if (!smallScreenHelper(smBreakpoint)) {
                    scrollWindow('sticky', $('body'), $sliderLogo);
                    $(document).on('scroll resize', function () {
                        scrollWindow('sticky', $('body'), $sliderLogo);
                    });
                } else {
                    $(document).on('scroll orientationchange', function () {
                        scrollWindow('sticky-mobile', $('body'), $headerOffset);
                    });
                }

                movexsElements();
                $(window).on('orientationchange', function () {
                    movexsElements();
                });

                $navbarPrimary.on('hidden.bs.dropdown', function () {
                    $megaMenu.removeClass('nav-open');
                });
                $navbarPrimary.on('show.bs.collapse', function () {
                    $headerMain.addClass('open-nav');
                    $megaMenu.addClass('is-open');
                }).on('hide.bs.collapse', function () {
                    $headerMain.removeClass('open-nav');
                    $megaMenu.removeClass('is-open');
                });

                $navbarPrimary.on('hidden.bs.collapse', function () {
                    $megaMenu.removeClass('nav-open');
                });

                $dropdownToggle.click(function (e) {
                    $(this).next('.dropdown-menu').first().slideToggle(300);
                });

            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page

                if (typeof soliloquy_slider === 'undefined' || false === soliloquy_slider) {
                    soliloquy_slider = '';
                }

                var soliloquy_container_posts = $('#soliloquy-container-posts'),
                    soliloquy_posts = $('#soliloquy-posts');

                soliloquy_slider = soliloquy_posts.soliloquy({
                    slideSelector: '.soliloquy-item',
                    speed: 500,
                    auto: 0,
                    useCSS: 0,
                    keyboard: true,
                    adaptiveHeight: 0,
                    adaptiveHeightSpeed: 400,
                    infiniteLoop: 1,
                    mode: 'fade',
                    pager: 1,
                    controls: 0,
                    onSliderLoad: function (currentIndex) {
                        soliloquy_container_posts.find('.soliloquy-active-slide').removeClass('soliloquy-active-slide').attr('aria-hidden', 'true');
                        soliloquy_container_posts.css({'height': 'auto', 'background-image': 'none'});
                        if (soliloquy_container_posts.find('.soliloquy-slider li').length > 1) {
                            soliloquy_container_posts.find('.soliloquy-controls').fadeTo(300, 1);
                        }
                        soliloquy_posts.find('.soliloquy-item:not(.soliloquy-clone):eq(' + currentIndex + ')').addClass('soliloquy-active-slide').attr('aria-hidden', 'false');
                        soliloquy_container_posts.find('.soliloquy-clone').find('*').removeAttr('id');
                        soliloquy_container_posts.find('.soliloquy-controls-direction').attr('aria-label', 'carousel buttons').attr('aria-controls', 'soliloquy-container-posts');
                        soliloquy_container_posts.find('.soliloquy-controls-direction a.soliloquy-prev').attr('aria-label', 'previous');
                        soliloquy_container_posts.find('.soliloquy-controls-direction a.soliloquy-next').attr('aria-label', 'next');
                    }
                });
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // Team page.
        'team': {
            init: function () {
                loadTeamProfiles($('.team-position-1 .team-profiles'));
                loadTeamProfiles($('.team-position-2 .team-profiles'));
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
